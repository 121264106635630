const getters = {
  sidebar: state => state.app.sidebar,
  language: state => state.app.language,

  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,

  tagsView: state => state.settings.tagsView,
  fixedHeader: state => state.settings.fixedHeader,
  sidebarLogo: state => state.settings.sidebarLogo,

  token: state => state.user.token,
  auditorCode: state => state.user.auditorCode,
  expireAt: state => state.user.expireAt,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  account: state => state.user.account,
  introduction: state => state.user.introduction,
  status: state => state.user.status,
  roles: state => state.user.roles,
  setting: state => state.user.setting,
  permission_routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  errorLogs: state => state.errorLog.logs,
  allNum: state => state.noVerifyNum.allNum,
  bjghNum: state => state.noVerifyNum.bjghNum,
  numMessage: state => state.noVerifyNum.numMessage
}
export default getters
