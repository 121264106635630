import Vue from 'vue'

import Cookies from 'js-cookie'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import '@/styles/index.scss' // global css

import App from './App'
import store from './store'
import router from './router'

import '@/icons' // icon
import '@/permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

import dayjs from 'dayjs'

// 全局方法挂载
Vue.prototype.$dayjs = dayjs
if (window.g.sentry.enable) {
  try {
    Sentry.init({
      Vue,
      dsn: window.g.sentry.dsn,
      autoSessionTracking: true,
      integrations: [
        new Integrations.BrowserTracing()
      ],
      logErrors: true,
      tracesSampleRate: 1.0,
      release: window.g.sentry.release // 这个地方要跟webpack插件里面的保持一致
    })
  } catch (error) {
    console.log('sentry catch = ', error)
  }
}

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
