import { asyncRoutes, constantRoutes } from '@/router'
/**
 * 通过meta.role判断是否与当前用户权限匹配
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.indexOf(role) >= 0)
  } else {
    return true
  }
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param asyncRoutes
 * @param roles
 */
function filterAsyncRouter(asyncRoutes, roles) {
  const accessedRouters = asyncRoutes.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles)
      }
      return true
    }
    return false
  })
  return accessedRouters
}

/**
 * 处理将配置issuer，如果配置新的issuer，则生成对应的路由地址
 * @returns asyncRoutes
 */
function disposeRoute() {
  // 添加通用审核-大行
  if (window && window.g && window.g.UNIVERSAL_AUDIT_BIG_CONFIG && window.g.UNIVERSAL_AUDIT_BIG_CONFIG.length > 0) {
    for (i = 0; i < asyncRoutes.length; i++) {
      if (asyncRoutes[i].path == '/audit') {
        window.g.UNIVERSAL_AUDIT_BIG_CONFIG.forEach((v, index) => {
          if (v.issuerId == '153544030300203737') {
            if (v.routerConfig.path !== 'GZGHFAST' && v.routerConfig.path !== 'GZGHFASTFINAL') {
              // 工行列表
              // const temp = {
              //   path: v.routerConfig.path,
              //   component: () => import('@/views/audit/listV2'),
              //   name: '定制卡' || v.routerConfig.name,
              //   meta: {
              //     ...v.routerConfig.meta,
              //     noCache: true,
              //     roles: ['admin', 'auditor']
              //   }
              // }
              // temp.meta.title = '定制卡'
              // asyncRoutes[i].children.splice(index, 0, temp)
            } else {
              // 工快初审，复审
              const temp = {
                path: v.routerConfig.path,
                component: () => import('@/views/audit/listFast'),
                name: v.routerConfig.name,
                meta: {
                  ...v.routerConfig.meta,
                  noCache: true,
                  roles: ['admin', 'auditor']
                }
              }
              if (temp.name === 'GZGHFAST') { 
                temp.meta.title = '初审'
              }
              if (temp.name === 'GZGHFASTFINAL') { 
                temp.meta.title = '复审'
              }
              asyncRoutes[i].children.splice(index, 0, temp)
            }
          } else {
            if (v.routerConfig.path.indexOf('FAST') !== -1) {
              // 邮储借记初审，复审
              const temp = {
                path: v.routerConfig.path,
                component: () => import('@/views/audit/listFast'),
                name: v.routerConfig.name,
                meta: {
                  ...v.routerConfig.meta,
                  noCache: true,
                  roles: ['admin', 'auditor']
                }
              }
              asyncRoutes[i].children.splice(index, 0, temp)
            } else {
              const temp = {
                path: v.routerConfig.path,
                component: () => import('@/views/audit/universalBankCard'),
                name: v.routerConfig.name,
                meta: {
                  ...v.routerConfig.meta,
                  noCache: true,
                  roles: ['admin', 'auditor']
                }
              }
              asyncRoutes[i].children.splice(index, 0, temp)
            }
          }
        })
        asyncRoutes[i].children = unique(asyncRoutes[i].children)
        break
      }
    }
  }

  // 通用审核-普通银行
  if (window && window.g && window.g.UNIVERSAL_AUDIT_CONFIG && window.g.UNIVERSAL_AUDIT_CONFIG.length > 0) {
    for (var i = 0; i < asyncRoutes.length; i++) {
      if (asyncRoutes[i].path == '/audit') {
        var childs = asyncRoutes[i].children

        window.g.UNIVERSAL_AUDIT_CONFIG.forEach(v => {
          childs.push({
            path: v.routerConfig.path,
            component: () => import('@/views/audit/universalBankCard'),
            name: v.routerConfig.name,
            meta: {
              ...v.routerConfig.meta,
              noCache: true,
              roles: ['admin', 'auditor']
            }
          })
        })
        asyncRoutes[i].children = unique(asyncRoutes[i].children)
        break
      }
    }
  }

  // 添加通用查询-大行
  if (window && window.g && window.g.UNIVERSAL_QUERY_BIG_CONFIG && window.g.UNIVERSAL_QUERY_BIG_CONFIG.length > 0) {
    for (i = 0; i < asyncRoutes.length; i++) {
      if (asyncRoutes[i].path == '/query') {
        window.g.UNIVERSAL_QUERY_BIG_CONFIG.forEach((v, index) => {
          if (v.issuerId == '153544030300203737') {
            const temp = {
              path: v.routerConfig.path,
              component: () => import('@/views/query/gzghQuery'),
              name: v.routerConfig.name,
              meta: {
                ...v.routerConfig.meta,
                noCache: true,
                roles: ['admin', 'auditor']
              }
            }
            temp.meta.title = '定制卡'
            asyncRoutes[i].children.splice(index, 0, temp)
          } else {
            const temp = {
              path: v.routerConfig.path,
              component: () => import('@/views/query/universalBigQuery'),
              name: v.routerConfig.name,
              meta: {
                ...v.routerConfig.meta,
                noCache: true,
                roles: ['admin', 'auditor']
              }
            }
            asyncRoutes[i].children.splice(index, 0, temp)
          }
        })
        asyncRoutes[i].children = unique(asyncRoutes[i].children)
        break
      }
    }
  }

  // ---------------------------------------低优先级代码----start-------------------------------------------------

  // 添加通用审核-低优先级
  if (window && window.g && window.g.UNIVERSAL_AUDIT_AND_QUERY_CONFIG_LOW && window.g.UNIVERSAL_AUDIT_AND_QUERY_CONFIG_LOW.length > 0) {
    for (i = 0; i < asyncRoutes.length; i++) {
      if (asyncRoutes[i].path == '/auditLow') {
        asyncRoutes[i].children.splice(1)
        window.g.UNIVERSAL_AUDIT_AND_QUERY_CONFIG_LOW.forEach((v, index) => {
          const temp = {
            path: v.routerConfig.path,
            component: () => import('@/views/lowIssuer/auditLow/universalBankCard'),
            name: v.routerConfig.name,
            meta: {
              ...v.routerConfig.meta,
              noCache: true,
              roles: ['admin', 'auditor']
            }
          }
          asyncRoutes[i].children.splice(index, 0, temp)
        })
        asyncRoutes[i].children = unique(asyncRoutes[i].children)

        window.g.UNIVERSAL_AUDIT_OTHER_CONFIG.forEach((v, index) => {
          asyncRoutes[i].children.splice(index, 0, {
            path: v.routerConfig.path,
            component: () => import('@/views/lowIssuer/auditLow/listV2'),
            name: v.routerConfig.name,
            meta: {
              ...v.routerConfig.meta,
              noCache: true,
              roles: ['admin', 'auditor']
            }
          })
        })
        asyncRoutes[i].children = unique(asyncRoutes[i].children)
        break
      }
    }
  }

  // 添加通用查询-低优先级
  if (window && window.g && window.g.UNIVERSAL_AUDIT_AND_QUERY_CONFIG_LOW && window.g.UNIVERSAL_AUDIT_AND_QUERY_CONFIG_LOW.length > 0) {
    for (i = 0; i < asyncRoutes.length; i++) {
      if (asyncRoutes[i].path == '/queryLow') {
        asyncRoutes[i].children.splice(0)
        window.g.UNIVERSAL_AUDIT_AND_QUERY_CONFIG_LOW.forEach((v, index) => {
          const temp = {
            path: v.routerConfig.path,
            component: () => import('@/views/lowIssuer/queryLow/universalBankQuery'),
            name: v.routerConfig.name,
            meta: {
              ...v.routerConfig.meta,
              noCache: true,
              roles: ['admin', 'auditor']
            }
          }
          asyncRoutes[i].children.splice(index, 0, temp)
        })

        window.g.UNIVERSAL_AUDIT_OTHER_CONFIG.forEach((v, index) => {
          const temp = {
            path: v.routerConfig.path,
            component: () => import('@/views/lowIssuer/queryLow/gzghQuery'),
            name: v.routerConfig.name,
            meta: {
              ...v.routerConfig.meta,
              noCache: true,
              roles: ['admin', 'auditor']
            }
          }
          asyncRoutes[i].children.splice(index, 0, temp)
        })

        asyncRoutes[i].children = unique(asyncRoutes[i].children)
        break
      }
    }
  }
  // ---------------------------------------低优先级代码----end-------------------------------------------------

  return asyncRoutes
}

// 数组去重
function unique(arr) {
  for (var i = 0; i < arr.length - 1; i++) {
    for (var j = i + 1; j < arr.length; j++) {
      if (arr[i].name == arr[j].name) {
        arr.splice(j, 1)
        // 因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
        j--
      }
    }
  }
  return arr
}

const state = {
  routers: constantRoutes,
  addRouters: []
}
const mutations = {
  SET_ROUTERS: (state, routers) => {
    state.addRouters = routers
    state.routers = constantRoutes.concat(routers)
  }
}
const actions = {
  GenerateRoutes({ commit }, data) {
    return new Promise(resolve => {
      const { roles } = data
      const accessedRouters = filterAsyncRouter(disposeRoute(), roles)
      commit('SET_ROUTERS', accessedRouters)
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
