import request from '@/utils/request'

export function Logout() {
  return request({
    url: '/auth/Logout',
    method: 'post'
  })
}

export function Login(username, password) {
  const data = {
    account: username,
    password: password
  }
  return request({
    url: '/auth/Login',
    method: 'post',
    data
  })
}

export function CurrentUser(data) {
  return request({
    url: '/user/CurrentUser',
    method: 'post',
    data
  })
}

export function Version(data) {
  return request({
    url: '/auth/Version',
    method: 'post',
    data
  })
}

export function GetRefreshToken(data) {
  return request({
    url: '/auth/refreshToken',
    method: 'post',
    data
  })
}

