
import { getAuditWaitingBoard } from '@/api/screening'
const state = {
  allNum: 0,
  bjghNum: 0,
  gzghNum: 0,
  szghNum: 0,
  cqrcbNum: 0,
  nbcbNum: 0,
  xayktNum: 0,
  sztNum: 0,
  numMessage: '所有24小时未审核数量为：0；北京工行未审核数量为：0'
}

const mutations = {
  SET_ALL_NO_VERIFY_NUM: (state, allNum) => {
    state.allNum = allNum
  },
  SET_ALL_NO_VERIFY_BJGH_NUM: (state, bjghNum) => {
    state.bjghNum = bjghNum
  },
  SET_ALL_NO_VERIFY_GZGH_NUM: (state, gzghNum) => {
    state.gzghNum = gzghNum
  },
  SET_ALL_NO_VERIFY_SZGH_NUM: (state, szghNum) => {
    state.szghNum = szghNum
  },
  SET_ALL_NO_VERIFY_CQRCB_NUM: (state, cqrcbNum) => {
    state.cqrcbNum = cqrcbNum
  },
  SET_ALL_NO_VERIFY_NBCB_NUM: (state, nbcbNum) => {
    state.nbcbNum = nbcbNum
  },
  SET_ALL_NO_VERIFY_XAYKT_NUM: (state, xayktNum) => {
    state.xayktNum = xayktNum
  },
  SET_ALL_NO_VERIFY_SZT_NUM: (state, sztNum) => {
    state.sztNum = sztNum
  },
  SET_ALL_MESSAGE: (state, message) => {
    state.numMessage = message
  }
}

const actions = {
  // 获取所有未审核数量
  getAuditCount({ commit }) {
    return new Promise((resolve, reject) => {
      Promise.all([getAuditWaitingBoard({ source: 'Aliyun' }), getAuditWaitingBoard({ source: 'UnionpayCloud' })])
        .then(res => {
          const res0 = res[0]
          const res1 = res[1]

          if (!(res0 && res1 && res0.data.data.auditWaitingBoard.issuers && res1.data.data.auditWaitingBoard.issuers)) {
            throw new Error('结果返回错误')
          }
          const dataNumber0 = res0.data.data.auditWaitingBoard.issuers
          const dataNumber1 = res1.data.data.auditWaitingBoard.issuers
          var showData = []

          window.g.UNAUDITLIST.forEach(element => {
            dataNumber0.forEach(item => {
              if (item.issuerId == element) {
                let sameDate = {}
                dataNumber1.forEach(item1 => {
                  if (item1.issuerId == element) {
                    sameDate = item1
                  }
                })
                const temp = {
                  name: '定制卡', //  item.name,
                  number: item.number + sameDate.number
                }
                showData.push(temp)
              }
            })
          })

          var total = 0
          var childMsg = ''
          showData.forEach(item => {
            childMsg += item.name + ':\xa0' + item.number + '\xa0\xa0\xa0\xa0'
          })
          total = res0.data.data.auditWaitingBoard.total
          var message = '所有未审核的数量为: ' + total + '\xa0\xa0\xa0\xa0' + childMsg

          commit('SET_ALL_MESSAGE', message)
          resolve(res)
        }).catch(err => {
          // var message = '所有未审核数量为：' + 0 + '；' + '北京工行：' + 0 + '；' + '广州工行：' + 0 + '；' + '重庆农商行：' + 0
          var message = '所有未审核数量为：' + 0 + '；' + '定制卡: ' + 0 + '；'
          commit('SET_ALL_MESSAGE', message)
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
