import Cookies from 'js-cookie'

const TokenKey = 'token'
const AuditorKey = 'auditorCode'
const ExpireAtKey = 'expireAt'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getAuditorCode() {
  return Cookies.get(AuditorKey)
}

export function setAuditorCode(auditorCode) {
  return Cookies.set(AuditorKey, auditorCode)
}

export function removeAuditorCode() {
  return Cookies.remove(AuditorKey)
}

export function getExpireAt() {
  return Cookies.get(ExpireAtKey)
}

export function setExpireAt(expireAt) {
  return Cookies.set(ExpireAtKey, expireAt)
}

export function removeExpireAt() {
  return Cookies.remove(ExpireAtKey)
}
