/* Layout */
import Layout from '@/layout'
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [

  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [{
      path: '/redirect/:path(.*)',
      component: () => import('@/views/redirect/index')
    }]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },

  {
    path: '/auth-redirect',
    component: () => import('@/views/login/auth-redirect'),
    hidden: true
  },

  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true
  },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    hidden: true,
    children: [{
      path: 'dashboard',
      name: 'Dashboard',
      component: () => import('@/views/dashboard/index'),
      meta: {
        title: '首页',
        icon: 'dashboard'
      }
    }]
  }

]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [{
  path: '/user',
  component: Layout,
  redirect: 'noredirect',
  name: 'userManage',
  meta: {
    title: '用户管理',
    icon: 'user',
    roles: ['admin']
  },
  alwaysShow: true,
  children: [
    { path: 'userList', component: () => import('@/views/user/list'), name: 'userList', meta: { title: '用户列表', roles: ['admin'] }},
    { path: 'userGroup', component: () => import('@/views/user/group'), name: 'userGroup', meta: { title: '用户分组', roles: ['admin'] }}
    // { path: 'userProfile', component: () => import('@/views/user/edit'), name: 'userProfile', meta: { title: '修改资料', roles: ['admin'] }}
  ]
},

// 图片查询一
{
  path: '/query',
  component: Layout,
  redirect: 'noredirect',
  name: 'query',
  meta: {
    title: '查询',
    icon: 'search',
    roles: ['admin', 'auditor']
  },
  alwaysShow: true,
  children: [
    ]
},

// 审核图库
{
  path: '/auditImg',
  component: Layout,
  redirect: 'noredirect',
  name: 'auditImg',
  meta: {
    title: '审核图库',
    icon: 'form',
    roles: ['admin', 'auditor']
  },
  alwaysShow: true,
  children: [{
    path: 'GZGH',
    component: () => import('@/views/auditImg/list'),
    name: 'GZGH',
    meta: {
      title: '定制卡',
      noCache: true,
      customerName: 'GZGH',
      customerId: '153544030300203737',
      roles: ['admin', 'auditor']
    }
  }]
},

// 上级审核一
{
  path: '/reconfirm',
  component: Layout,
  redirect: 'noredirect',
  name: 'reconfirm',
  meta: {
    title: '上级审核',
    icon: 'peoples',
    roles: ['admin']
  },
  alwaysShow: true,
  children: [{
    path: 'GZGH',
    component: () => import('@/views/audit/listReconfirm'),
    name: 'GZGH',
    meta: {
      title: '定制卡',
      noCache: true,
      customerName: 'GZGH',
      customerId: '153544030300203737',
      roles: ['admin']
    }
  },
  // {
  //   path: 'UNIVERSAL',
  //   component: () => import('@/views/audit/universalBankReconfirmList'),
  //   name: 'universal',
  //   meta: {
  //     title: '其他银行',
  //     noCache: true,
  //     customerName: 'universal',
  //     roles: ['admin']
  //   }
  // },
  {
    path: 'auditDetailReconfirm/',
    hidden: true,
    component: () => import('@/views/audit/listFast'),
    name: 'auditDetailReconfirm',
    meta: {
      noCache: true,
      title: '复审图片详情',
      roles: ['admin']
    }
  }]
},

// 上级审核二
// {
//   path: '/reconfirmLow',
//   component: Layout,
//   redirect: '/UNIVERSALLOW',
//   name: 'reconfirmLow',
//   meta: {
//     title: '上级审核二',
//     icon: 'peoples',
//     roles: ['admin']
//   },
//   children: [{
//     path: 'UNIVERSALLOW',
//     name: 'universalLow',
//     component: () => import('@/views/lowIssuer/auditLow/universalBankReconfirmList'),
//     meta: {
//       title: '上级审核二',
//       noCache: true,
//       customerName: 'universalLow',
//       roles: ['admin']
//     }
//   }]
// },

// 图片审核一
{
  path: '/audit',
  component: Layout,
  redirect: 'noredirect',
  name: 'audit',
  meta: {
    title: '图片审核',
    icon: 'excel',
    roles: ['admin', 'auditor']
  },
  alwaysShow: true,
  children: [
    // 快审审核详情路由
    {
      path: '/auditFast',
      hidden: true,
      component: () => import('@/views/audit/listFast'),
      name: 'auditFast',
      meta: {
        noCache: true,
        title: '审核详情',
        roles: ['admin', 'auditor']
      }
    }
  ]
},

// 审核工作统计
{
  path: '/auditStatistics',
  component: Layout,
  redirect: '/auditStatistics',
  meta: {
    title: '审核工作统计',
    icon: 'user',
    roles: ['admin']
  },
  children: [{
    path: 'auditStatistics',
    name: 'auditStatistics',
    component: () => import('@/views/auditStatistics/index'),
    meta: {
      title: '审核工作统计',
      icon: 'tree',
      roles: ['admin']
    }
  }]
},
// 审核历史
// {
//   path: '/auditHistory',
//   component: Layout,
//   redirect: '/auditHistory',
//   children: [{
//     path: 'auditHistory',
//     name: 'auditHistory',
//     component: () => import('@/views/auditHistory/index'),
//     meta: {
//       title: '审核历史',
//       icon: 'eye',
//       roles: ['admin', 'auditor']
//     }
//   }]
// },

// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}

]

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
