import store from '@/store'
import { getToken } from '@/utils/auth'
import { baseUrl, baseUrl_UnionpayCloud, baseUrl_LowPartition, ACTIVITY_URL } from '@/utils/urlConfig'
import axios from 'axios'

// create an axios instance
const service = axios.create({
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
  // Do something before request is sent
  if (store.getters.token) {
    if (store.getters.token !== getToken()) {
      alert('登录会话过期，请重新登录!')
      store.dispatch('user/FedLogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
  config.headers['token'] = getToken()

  config.headers['Distribute-Cloud'] = 'Aliyun'
  config.baseURL = baseUrl()

  const currentUrl = config.url
  let currentIssuer = ''
  if (config.data && config.data.issuerId) {
    currentIssuer = config.data.issuerId
  } else if (config.data && config.data.customerId) {
    currentIssuer = config.data.customerId
  } else if (config.params && config.params.issuerId) {
    currentIssuer = config.params.issuerId
  } else if (config.params && config.params.customerId) {
    currentIssuer = config.params.customerId
  }

  console.log('currentUrl = ', currentUrl)
  // 通用接口处理，低分区
  if (currentUrl && window.g && window.g.distributeUrls && window.g.distributeIssuers_lowPartition) {
    if (window.g.distributeUrls.includes(currentUrl)) {
      console.log('包含了url')
      if (currentIssuer && window.g.distributeIssuers_lowPartition.includes(currentIssuer)) {
        config.headers['Distribute-Cloud'] = 'LowPartition'
        config.baseURL = baseUrl_LowPartition()
        console.log('包含了issuer')
      }
    }
  }

  // 通用接口处理，银联云
  if (currentUrl && window.g && window.g.distributeUrls && window.g.distributeIssuers_unionpay) {
    if (window.g.distributeUrls.includes(currentUrl)) {
      console.log('包含了url')
      if (currentIssuer && window.g.distributeIssuers_unionpay.includes(currentIssuer)) {
        config.headers['Distribute-Cloud'] = 'UnionpayCloud'
        config.baseURL = baseUrl_UnionpayCloud()
        console.log('包含了issuer')
      }
    }
  }

  // 工行两个接口单独处理，渝波说，不用处理也可以，那我就提取到这里，统一一个地方管理
  if (currentUrl && currentUrl.indexOf('.json') != -1) {
    // 处理工行，阿里云接口
    if (currentIssuer && currentIssuer == '153544030300203737' && window.g.distributeIssuers_lowPartition.includes(currentIssuer)) {
      // 处理工行
      config.headers['Distribute-Cloud'] = 'LowPartition'
      config.baseURL = ACTIVITY_URL()
    } else if (currentIssuer && currentIssuer == '153544030300203737' && window.g.distributeIssuers_unionpay.includes(currentIssuer)) {
      // 处理工行
      config.headers['Distribute-Cloud'] = 'UnionpayPartition'
      config.baseURL = ACTIVITY_URL()
    } else if (currentIssuer && currentIssuer == '153544030300203737') {
      config.headers['Distribute-Cloud'] = 'Aliyun'
      config.baseURL = ACTIVITY_URL()
    }
  }

  // 其他特殊处理
  // 1. /stat/getAuditWorkloadList 需要从低优先获取
  if (currentUrl && currentUrl.length > 0 && currentUrl.indexOf('/stat/getAuditWorkloadList') != -1) {
    config.headers['Distribute-Cloud'] = 'LowPartition'
    config.baseURL = baseUrl_LowPartition()
  }
  // 2. /stat/getAuditWaitingBoard 需要从两个云获取
  if (currentUrl && currentUrl.length > 0 && currentUrl.indexOf('/stat/getAuditWaitingBoard') != -1) {
    if (config.data && config.data.source && config.data.source == 'Aliyun') {
      config.headers['Distribute-Cloud'] = 'Aliyun'
      config.baseURL = baseUrl()
    } else if (config.data && config.data.source && config.data.source == 'LowPartition') {
      config.headers['Distribute-Cloud'] = 'LowPartition'
      config.baseURL = baseUrl_LowPartition()
    }
  }

  console.log('request.js  request  config = ', config)

  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
  response => {
    const res = response.data
    if (res.error_code !== 0 && (typeof res.error_code !== 'undefined')) {
      // :
      // ERROR_CODE_ACCOUNT_INACTIVE = 1002
      // ERROR_MSG_ACCOUNT_INACTIVE = '用户已过期，请联系管理员'
      // :
      // ERROR_CODE_NEED_ADMIN = 1014
      // ERROR_MSG_NEED_ADMIN = '需要管理员权限'
      // :
      // ERROR_CODE_USER_NOT_LOGIN = 1013
      // ERROR_MSG_USER_NOT_LOGIN = '用户未登录
      //
      if (res.error_code === 1002 || res.error_code === 1003 || res.error_code === 1014) {
        alert(res.data)
        store.dispatch('user/FedLogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })

        return Promise.reject(res.data)
      } else {
        return Promise.reject(response)
      }
    } else if (res.code === 1013) {
      alert('登录会话过期，请重新登录!')
      store.dispatch('user/FedLogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
      return Promise.reject(res.data)
    } else {
      return response
    }
  },
  error => {
    console.log('err' + error) // for debug
    // Message({
    //   message: error.message,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error)
  })

export default service
