import { CurrentUser, Login, Logout, GetRefreshToken } from '@/api/login'
import { getToken, removeToken, setToken, getAuditorCode, setAuditorCode, removeAuditorCode, getExpireAt, setExpireAt, removeExpireAt } from '@/utils/auth'

const state = {
  user: '',
  status: '',
  code: '',
  token: getToken(),
  auditorCode: getAuditorCode(),
  expireAt: getExpireAt(),
  name: '',
  account: '',
  avatar: '',
  introduction: '',
  roles: [],
  setting: {
    articlePlatform: []
  }
}

const mutations = {
  SET_CODE: (state, code) => {
    state.code = code
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_AUDITORCODE: (state, auditorCode) => {
    state.auditorCode = auditorCode
  },
  SET_EXPIREAT: (state, expireAt) => {
    state.expireAt = expireAt
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_SETTING: (state, setting) => {
    state.setting = setting
  },
  SET_STATUS: (state, status) => {
    state.status = status
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_ACCOUNT: (state, account) => {
    state.account = account
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  }
}

const actions = {
  // 用户名登录
  LoginByUsername({ commit }, userInfo) {
    const account = userInfo.account.trim()
    return new Promise((resolve, reject) => {
      Login(account, userInfo.password).then(response => {
        if (response.data.error_code == 0) {
          commit('SET_TOKEN', response.data.data.token)
          commit('SET_EXPIREAT', response.data.data.expireAt)
          commit('SET_AUDITORCODE', account)
          setToken(response.data.data.token)
          setExpireAt(response.data.data.expireAt)
          setAuditorCode(account)
          resolve()
        } else {
          reject(response.data.data)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 获取用户信息
  GetUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      CurrentUser(state.token).then(response => {
        console.log('GetUserInfo res = ', response)
        if (response.data.error_code != 0) { // 由于mockjs 不支持自定义状态码只能这样hack
          reject('获取用户信息失败')
        }

        const data = response.data
        commit('SET_ROLES', [data.data.role.roleName.toLowerCase()])
        commit('SET_NAME', data.data.username)
        commit('SET_AVATAR', data.data.avatar)
        commit('SET_ACCOUNT', data.data.account)
        // commit('SET_INTRODUCTION', data.introduction)
        resolve([data.data.role.roleName.toLowerCase()])
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 第三方验证登录
  // LoginByThirdparty({ commit, state }, code) {
  //   return new Promise((resolve, reject) => {
  //     commit('SET_CODE', code)
  //     loginByThirdparty(state.status, state.email, state.code).then(response => {
  //       commit('SET_TOKEN', response.data.token)
  //       setToken(response.data.token)
  //       resolve()
  //     }).catch(error => {
  //       reject(error)
  //     })
  //   })
  // },

  // 登出
  LogOut({ commit, state }) {
    return new Promise((resolve, reject) => {
      Logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_AUDITORCODE', '')
        commit('SET_EXPIREAT', '')
        removeToken()
        removeAuditorCode()
        removeExpireAt()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 前端 登出
  FedLogOut({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      commit('SET_AUDITORCODE', '')
      commit('SET_EXPIREAT', '')
      removeToken()
      removeExpireAt()
      removeAuditorCode()
      resolve()
    })
  },

  // 动态修改权限
  ChangeRoles({ commit }, role) {
    return new Promise(resolve => {
      commit('SET_AUDITORCODE', role)
      setAuditorCode(role)
    })
  },

  // 定时获取刷新token
  GetRefreshToken({ commit }) {
    return new Promise((resolve, reject) => {
      // 这个接口需要传入token作为body
      var params = { 'token': getToken() }
      GetRefreshToken(params)
        .then(response => {
          if (response.data.error_code == 0) {
            commit('SET_TOKEN', response.data.data.refreshToken)
            commit('SET_EXPIREAT', response.data.data.expireAt)
            setToken(response.data.data.refreshToken)
            setExpireAt(response.data.data.expireAt)
            resolve()
          } else {
            reject(response.data.data)
          }
        }).catch(err => {
          reject(err)
        })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
