import request from '@/utils/request'

export function GetScreeningList(query) {
  return request({
    url: '/screening/GetScreeningList',
    method: 'post',
    data: query
  })
}

export function GetScreeningItem(query) {
  return request({
    url: '/screening/GetScreeningItem',
    method: 'post',
    data: query
  })
}

/**
 * 工行查询在用，获取图片详情
 * 深圳工行也在用
 */
export function GetScreeningItemV2(query) {
  return request({
    url: '/screening/GetScreeningItem/v2',
    method: 'post',
    data: query
  })
}

/**
 * 当前在用的 百度阿里集成一起的 ai接口
 */
export function GetAIverify(query) {
  return request({
    url: '/ai/verify',
    method: 'post',
    data: query
  })
}

// 审核工作量统计
export function getAuditorWorkCount(query) {
  return request({
    url: '/stat/getAuditWorkloadList',
    method: 'post',
    data: query
  })
}

// 审核历史
export function getOwnWorkHistory(query) {
  return request({
    url: '/stat/getAuditHistoryList',
    method: 'post',
    data: query
  })
}

// 审核统计获取issuer列表
export function getAllIssuerData(data) {
  return request({
    url: '/utils/getIssuerList',
    method: 'post',
    data: data
  })
}

/** *************************通用接口设计******************************************/

/**
 * 获取审核列表，查询在用
 * @param {*} query
 */
export function GetScreeningListV4(query) {
  return request({
    url: '/universal/screening/GetScreeningList',
    method: 'post',
    data: query
  })
}

/**
 * 通用查询获取审核详情，查询在用
 * @param {*} query
 */
export function GetScreeningItemQuery(query) {
  return request({
    url: '/universal/screening/getScreeningItemQuery',
    method: 'post',
    data: query
  })
}

/**
 * 获取卡种信息
 * @param {*} query
 */
export function GetCardType(query) {
  return request({
    url: '/universal/screening/findCardType',
    method: 'post',
    data: query
  })
}

/** *************************审核列表，审核详情，提交审核，提交上级审核，工行卡核心统一接口******************************************/

/**
 * 新-获取待审核订单列表
 * @param {*} query
 */
export function getAuditImageList(query) {
  return request({
    url: '/screening/getAuditImageList',
    method: 'post',
    data: query
  })
}

/**
 * 新-获取待审核图片(更改图片，审核详情)
 * @param {*} query
 */
export function getAuditImage(query) {
  return request({
    url: '/screening/getAuditImage',
    method: 'post',
    data: query
  })
}

/**
 * 新-提交审核结果
 * @param {*} query
 */
export function submitAuditImageResult(query) {
  return request({
    url: '/screening/submitAuditImageResult',
    method: 'post',
    data: query
  })
}

/**
 * 新-提交上级审核
 * @param {*} query
 */
export function submitAuditSuperior(query) {
  return request({
    url: '/screening/submitAuditSuperior',
    method: 'post',
    data: query
  })
}

/**
 * 新-获取审核历史
 * @param {*} query
 */
export function getAuditHistory(query) {
  return request({
    url: '/screening/getAuditHistory',
    method: 'post',
    data: query
  })
}

/**
 * 获取审核看板数量
 * @param {*} query
 */
export function getAuditWaitingBoard(query) {
  return request({
    url: '/stat/getAuditWaitingBoard',
    method: 'post',
    data: query
  })
}
