/**
 * 获取不同环境baseUrl
 */
export function baseUrl() {
  return process.env.NODE_ENV === 'production' ? window.g.BASE_URL : process.env.VUE_APP_BASE_API
}

export function baseUrl_LowPartition() {
  return process.env.NODE_ENV === 'production' ? window.g.BASE_URL_LOW_PARTITION : process.env.VUE_APP_BASE_API_LOW_PARTITION
}

export function baseUrl_UnionpayCloud() {
  return process.env.NODE_ENV === 'production' ? window.g.BASE_URL_UNIONPAYCLOUD : process.env.VUE_APP_BASE_API_UNIONPAY
}

export function OBJ_URL() {
  return process.env.NODE_ENV === 'production' ? window.g.GZGH_ACTIVITY.OBJ_URL : process.env.VUE_APP_OBJ_URL
}

export function ACTIVITY_URL() {
  return process.env.NODE_ENV === 'production' ? window.g.GZGH_ACTIVITY.ACTIVITY_URL : process.env.VUE_APP_ACTIVITY_URL
}
