<template>
  <el-alert
    :title="this.$store.getters.numMessage"
    type="error"
    center
    effect="dark"
  />
</template>

<script>

export default {

}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .app-alert {
    display: inline-block;
    margin-left: 100px;
  }
</style>
